<template>
  <div class="accountset">
    <userheader menucurrent="2" />
    <div class="main-div">
      <div class="account-content wrap">
        <!-- 左侧导航 -->
        <userleft currindex="3" />
        <div class="main-box">
          <div class="account-main">
            <div class="item-block">
              <h3 class="title">我的订单<em></em></h3>
              <div class="info-cnt">
                <div class="content-rb ">
                  <el-tabs type="border-card" v-model="activeName">
                    <el-tab-pane label="待付款" name="second">
                      <template v-if="orderlist.length == 0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无订单</span>
                        </div>
                      </template>
                      <div class="orders-body">
                        <div
                          class="orders"
                          v-for="item in orderlist"
                          v-bind:key="item.id"
                        >
                          <div class="orders-head">
                            <ul class="orders-ul clearfix">
                              <li>下单时间: {{ item.addTime }}</li>
                              <li
                                class=""
                                @click="onnavtoorderdetail(item.orderId)"
                              >
                                订单号：<span class="num">{{
                                  item.orderNo
                                }}</span>
                              </li>
                              <li>
                                <template v-if="item.orderState == 110">
                                  <el-button
                                    size="mini"
                                    type="warning"
                                    plain
                                    @click="onnavtoorderdetail(item.orderId)"
                                  >
                                    立即付款
                                  </el-button>
                                  <el-popconfirm
                                    @confirm="onordercancelorder(item.orderId)"
                                    title="确定取消该订单?"
                                  >
                                    <el-button slot="reference" size="mini">
                                      取消订单
                                    </el-button>
                                  </el-popconfirm>
                                </template>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="order-item clearfix"
                            v-for="pro in item.detailList"
                            v-bind:key="pro.productId"
                          >
                            <div class="order-pro">
                              <div class="order-img">
                                <a
                                  href="javascript:;"
                                  @click="onnavtoprodetail(pro)"
                                  class="link"
                                >
                                  <div>
                                    <img :src="pro.img" class="image" />
                                  </div>
                                </a>
                              </div>
                              <div class="order-info">
                                <div class="info-box">
                                  <a
                                    href="javascript:;"
                                    @click="onnavtoprodetail(pro)"
                                    class="link"
                                  >
                                    <p class="order-title">{{ pro.title }}</p>
                                  </a>
                                  <p class="info">
                                    下单时间: {{ item.addTime }}
                                  </p>
                                  <p class="info">数量: {{ pro.count }}</p>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="order-price">
                              需付款: ￥{{ item.totalPrice }}
                            </div>
                            <div class="order-status">
                              {{ item.orderStateName }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageIndex"
                        :page-sizes="[10, 30, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                      >
                      </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="待发货" name="third">
                      <template v-if="orderlist.length == 0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无订单</span>
                        </div>
                      </template>
                      <div class="orders-body">
                        <div
                          class="orders"
                          v-for="item in orderlist"
                          v-bind:key="item.id"
                        >
                          <div class="orders-head">
                            <ul class="orders-ul clearfix">
                              <li>下单时间: {{ item.addTime }}</li>
                              <li
                                class=""
                                @click="onnavtoorderdetail(item.orderId)"
                              >
                                订单号：<span class="num">{{
                                  item.orderNo
                                }}</span>
                              </li>
                              <li></li>
                            </ul>
                          </div>
                          <div
                            class="order-item clearfix"
                            v-for="pro in item.detailList"
                            v-bind:key="pro.productId"
                          >
                            <div class="order-pro">
                              <div class="order-img">
                                <a
                                  href="javascript:;"
                                  class="link"
                                  @click="onnavtoprodetail(pro)"
                                >
                                  <div>
                                    <img :src="pro.img" class="image" />
                                  </div>
                                </a>
                              </div>
                              <div class="order-info">
                                <div class="info-box">
                                  <a
                                    href="javascript:;"
                                    @click="onnavtoprodetail(pro)"
                                    class="link"
                                  >
                                    <p class="order-title">{{ pro.title }}</p>
                                  </a>
                                  <p class="info">
                                    下单时间: {{ item.addTime }}
                                  </p>
                                  <p class="info">数量: {{ pro.count }}</p>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="order-price">
                              需付款: ￥{{ item.totalPrice }}
                            </div>
                            <div class="order-status">
                              {{ item.orderStateName }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageIndex"
                        :page-sizes="[10, 30, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                      >
                      </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="已完成" name="four">
                      <template v-if="orderlist.length == 0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无订单</span>
                        </div>
                      </template>
                      <div class="orders-body">
                        <div
                          class="orders"
                          v-for="item in orderlist"
                          v-bind:key="item.id"
                        >
                          <div class="orders-head">
                            <ul class="orders-ul clearfix">
                              <li>下单时间: {{ item.addTime }}</li>
                              <li
                                class=""
                                @click="onnavtoorderdetail(item.orderId)"
                              >
                                订单号：<span class="num">{{
                                  item.orderNo
                                }}</span>
                              </li>
                              <li></li>
                            </ul>
                          </div>
                          <div
                            class="order-item clearfix"
                            v-for="pro in item.detailList"
                            v-bind:key="pro.productId"
                          >
                            <div class="order-pro">
                              <div class="order-img">
                                <a
                                  href="javascript:;"
                                  @click="onnavtoprodetail(pro)"
                                  class="link"
                                >
                                  <div>
                                    <img :src="pro.img" class="image" />
                                  </div>
                                </a>
                              </div>
                              <div class="order-info">
                                <div class="info-box">
                                  <a
                                    href="javascript:;"
                                    @click="onnavtoprodetail(pro)"
                                    class="link"
                                  >
                                    <p class="order-title">{{ pro.title }}</p>
                                  </a>
                                  <p class="info">
                                    下单时间: {{ item.addTime }}
                                  </p>
                                  <p class="info">数量: {{ pro.count }}</p>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="order-price">
                              需付款: ￥{{ item.totalPrice }}
                            </div>
                            <div class="order-status">
                              {{ item.orderStateName }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageIndex"
                        :page-sizes="[10, 30, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                      >
                      </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="所有订单" name="first">
                      <template v-if="orderlist.length == 0">
                        <div class="empty">
                          <i class="icon el-icon-sunrise"></i>
                          <span>暂无订单</span>
                        </div>
                      </template>
                      <div class="orders-body">
                        <div
                          class="orders"
                          v-for="item in orderlist"
                          v-bind:key="item.id"
                        >
                          <div class="orders-head">
                            <ul class="orders-ul clearfix">
                              <li>下单时间: {{ item.addTime }}</li>
                              <li
                                class=""
                                @click="onnavtoorderdetail(item.orderId)"
                              >
                                订单号：<span class="num">{{
                                  item.orderNo
                                }}</span>
                              </li>
                              <li>
                                <template v-if="item.orderState == 110">
                                  <el-button
                                    size="mini"
                                    type="warning"
                                    plain
                                    @click="onnavtoorderdetail(item.orderId)"
                                  >
                                    立即付款
                                  </el-button>
                                  <el-popconfirm
                                    @confirm="onordercancelorder(item.orderId)"
                                    title="确定取消该订单?"
                                  >
                                    <el-button slot="reference" size="mini">
                                      取消订单
                                    </el-button>
                                  </el-popconfirm>
                                </template>
                              </li>
                            </ul>
                          </div>
                          <div
                            class="order-item clearfix"
                            v-for="pro in item.detailList"
                            v-bind:key="pro.productId"
                          >
                            <div class="order-pro">
                              <div class="order-img">
                                <a
                                  href="javascript:;"
                                  @click="onnavtoprodetail(pro)"
                                  class="link"
                                >
                                  <div>
                                    <img :src="pro.img" class="image" />
                                  </div>
                                </a>
                              </div>
                              <div class="order-info">
                                <div class="info-box">
                                  <a
                                    href="javascript:;"
                                    @click="onnavtoprodetail(pro)"
                                    class="link"
                                  >
                                    <p class="order-title">{{ pro.title }}</p>
                                  </a>
                                  <p class="info">
                                    下单时间: {{ item.addTime }}
                                  </p>
                                  <p class="info">数量: {{ pro.count }}</p>
                                </div>
                              </div>
                              <div class="clearfix"></div>
                            </div>
                            <div class="order-price">
                              需付款: ￥{{ item.totalPrice }}
                            </div>
                            <div class="order-status">
                              {{ item.orderStateName }}
                            </div>
                          </div>
                        </div>
                      </div>
                      <el-pagination
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange"
                        :current-page="pageIndex"
                        :page-sizes="[10, 30, 50]"
                        :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="totalCount"
                      >
                      </el-pagination>
                    </el-tab-pane>
                  </el-tabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <userfooter />
  </div>
</template>

<script>
import userheader from '@/components/userheader.vue';
import userfooter from '@/components/userfooter.vue';
import userleft from '@/components/userleft.vue';
import { ordermyorderlist, ordercancelorder } from '@/api/order';
export default {
  name: 'App',
  components: {
    userheader: userheader,
    userfooter: userfooter,
    userleft: userleft
  },
  data() {
    return {
      activeName: 'second',
      orderstate: 110,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      orderlist: []
    };
  },
  methods: {
    onnavtoprodetail(item) {
      console.log(item);
      return;
      // let path="/product/detailclothing?id="+item.orderDetailId;
      // if(item.isCustom==1){
      //   path="/product/detailassess?id="+item.orderDetailId;
      // }else if(item.isCustom==0){
      //   path="/product/detail?uniqueNo="+item.uniqueNo;
      // }
      // window.open(path, '_blank');
    },
    onnavtoorderdetail(id) {
      let routeData = this.$router.resolve({
        path: '/shopping/orderdetail',
        query: { id: id }
      });
      window.open(routeData.href, '_blank');
    },
    isShow() {
      this.show = !this.show;
    },
    hideDetail() {
      this.detailShow = false;
    },
    handleCurrentChange(item) {
      console.log(item);
      this.pageIndex = item;
      this.onordermyorderlist();
    },
    handleSizeChange(item) {
      this.pageSize = item;
      this.onordermyorderlist();
    },
    oncancelorder() {},
    onordermyorderlist() {
      let obj = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        orderstate: this.orderstate
      };
      ordermyorderlist(obj).then(res => {
        if (res.data.code === 1) {
          this.orderlist = res.data.result.list;
          this.totalCount = res.data.result.totalCount;
        }
      });
    },
    onordercancelorder(orderid) {
      let obj = {
        orderid: orderid
      };

      ordercancelorder(obj).then(res => {
        if (res.data.code === 1) {
          this.onordermyorderlist();
        }
      });
    }
  },
  mounted() {
    this.onordermyorderlist();
  },
  watch: {
    activeName: function(val) {
      this.pageIndex = 1;
      if (val == 'second') {
        this.orderstate = 110;
      } else if (val == 'third') {
        this.orderstate = 120;
      } else if (val == 'four') {
        this.orderstate = 160;
      } else if (val == 'first') {
        this.orderstate = null;
      } else {
        this.orderstate = 110;
      }
      this.onordermyorderlist();
    }
  }
};
</script>
<style scoped>
/**公共css开始**/
body {
  background-color: #eaecef;
  /* height: 100%; */
}
.clearfix {
  clear: both;
}
.wrap {
  width: 1190px;
  margin-left: auto;
  margin-right: auto;
}
.accountset {
  min-height: 100%;
  height: auto !important;
  height: 100%;
  position: relative;
}
.accountset .main-div {
  margin-top: 40px;
  padding-bottom: 210px;
}
.accountset .account-content {
  padding: 20px 0 0;
  overflow: hidden;
  position: relative;
}

.acount-card {
  background-position: 0 -172px;
  background-image: url('../../../assets/img/account-ico.png');
}
.accountset .main-box {
  float: right;
  width: 940px;
}
.accountset .item-block {
  padding: 20px;
  min-height: 720px;
  background-color: #fff;
}
.accountset .item-block .title {
  font-size: 28px;
  color: #404040;
  line-height: 46px;
  border-bottom: 1px solid #e0e0e0;
  font-weight: 400;
}
.accountset .item-block .title em {
  font-size: 16px;
  color: #404040;
}
.accountset .info-cnt {
  padding: 30px 0;
}

.accountset .info-cnt >>> .el-form-item__label {
  color: #a0a0a0;
  min-width: 84px;
  font-size: 16px;
}

.accountset .info-cnt >>> .el-form-item__content button {
  background-image: linear-gradient(196deg, #5aa5ff, #0d7bff);
  color: #fff;
  border: none;
}
.accountset >>> form.elform {
  width: 460px;
}
/**公共css结束**/
.accountset .content-rb .empty {
  background: #fff;
  width: 100%;
  margin: 0 auto;
  text-align: center;
  padding-top: 100px;
  padding-bottom: 100px;
}
.accountset .content-rb .empty i {
  font-size: 40px;
  color: #ddd;
  width: 100%;
  display: inline-block;
}
.accountset .content-rb .empty span {
  width: 100%;
  display: inline-block;
  font-size: 16px;
  color: #ddd;
}
.accountset >>> form.elform .phone .el-input {
  width: 220px;
  margin-right: 10px;
}
.accountset .info-cnt >>> .el-form-item__content button.sendcode {
  background: #fff;
  border: 1px solid #dcdfe6;
  color: #606266;
}

.accountset .info-cnt .order-item {
  padding: 10px 5px;
}
li {
  list-style: none;
  padding: 0;
  margin: 0;
}
.orders {
  border: 1px solid #e5e5e5;
  margin-bottom: 5px;
}
.orders .orders-head .orders-ul {
  padding: 5px 5px;
  background: #f5f5f5;
  color: #aaa;
}
.orders .orders-head .orders-ul li {
  font-size: 13px;
  color: #aaa;
  line-height: 26px;
  /* float: left; */
  width: 105px;
  text-align: center;
  height: 38px;
  margin-right: 25px;
  cursor: pointer;
  display: inline;
}
.orders .orders-head .orders-ul li .num {
  color: #3a8ee6;
}
.orders .orders-head li .el-button {
  margin-right: 10px;
}

.order-item .order-img {
  float: left;
}
.order-item .order-img div {
  width: 90px;
  height: 90px;
  background-image: url(//p0.meituan.net/travelcube/214b841….png);
  background-position: 50%;
  background-size: cover;
  background-repeat: no-repeat;
}
.order-item .order-img img {
  width: 90px;
  height: 90px;
  border-radius: 4px;
}
.order-item .order-info {
  float: left;
  height: 90px;
  width: 325px;
  position: relative;
}
.order-item .order-info .info-box {
  position: absolute;
  top: 30%;
  transform: translateY(-50%);
}
.order-item .order-info .info-box .order-title {
  font-size: 16px;
  color: #222222;
  margin: 10px 0 10px 15px;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.order-item .order-info .info-box .info {
  font-size: 12px;
  color: #666666;
  margin-left: 15px;
  height: 18px;
}
.order-item .order-info .info-box .info {
  font-size: 12px;
  color: #666666;
  margin-left: 15px;
  height: 18px;
}
.order-item .order-price {
  float: left;
  font-size: 12px;
  color: #666666;
  line-height: 90px;
  text-align: center;
  width: 160px;
}
.order-item .order-status {
  float: left;
  font-size: 12px;
  color: #ff7800;
  line-height: 90px;
  text-align: center;
  width: 80px;
}

.clearfix:after {
  content: ' ';
  visibility: hidden;
  display: block;
  height: 0;
  clear: both;
}
.order-item .order-pro {
  width: 450px;
  float: left;
}
</style>
